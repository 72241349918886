import React, { useState } from 'react';
import { useEffect } from 'react';

export const defaultContextValue = {
	windowWidth: 0,
	windowHeight: 0,
};

const GlobalContext = React.createContext(defaultContextValue);

export const GlobalContextProviderComponent = ({ children }) => {

	const updateWindowDimensions = () => {
		setState(prev => ({
			...prev, 
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight,
		}));
	};

	useEffect(() => {
		updateWindowDimensions();

		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => updateWindowDimensions(), 10);
		};
		
		window.addEventListener('resize', resizeListener);
	
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const contextValue = {
		...defaultContextValue,
	};

	const [ state, setState ] = useState(contextValue); 
	return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>;
};

export default GlobalContext;